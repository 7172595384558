<template>
  <div>
    <div class="bar">
      <!-- <img src="../assets/images/left.png" alt="" class="orderImg"> -->
      <!-- <van-icon name="arrow-left" /> -->
      <!-- <span class="title">个人中心</span> -->
      <!-- <van-nav-bar
        left-text="返回"
        left-arrow
        bind:click-left="onClickLeft"
        
      /> -->
      <van-nav-bar
  left-text="返回"
  left-arrow
  @click-left="onClickLeft"
  
/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TaxiH5Nav',

  data() {
    return {}
  },

  mounted() {},

  methods: {
      onClickLeft() {
        console.log(111);
     window.history.back(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.bar {
  // position: fixed;
  // top: 0;
  // z-index: 99;
  // width: calc(100vw - 20px);
  // height: 40px;
  // background-color: #eee;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // padding: 0 10px;
  // display: flex;

  .title {
    text-align: center;
  }
}
</style>