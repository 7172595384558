<template>
  <div class="bigbox">
    <div>
      <nav2 />
    </div>
    <div style="margin-top:27pt">
      <!-- <van-field
        v-model="value"
        rows="1"
        autosize
        style="background:#FDFDFD;border:solid 1px #eee;width:90%;margin:12pt 5%;border-radius:16pt"
        type="textarea"
        placeholder="请选择投诉理由"
      />-->
      <div style="width:90%;margin-left:5%;border-radius: 15px;">
        <van-field
          readonly
          clickable
         
          :value="projectNameValue"
          placeholder="选择投诉理由"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            title=""
            show-toolbar
            :columns="projectListArr"
            @cancel="showPicker = false"
            @confirm="changeConfirm"
           
          />
        </van-popup>
      </div>

     
      <van-field
        v-model="message"
        rows="2"
        autosize
        style="background:#FDFDFD;height:100px;width:90%;margin:20px 5%;border:solid 1px #eee; border-radius:16pt"
        type="textarea"
        placeholder="请在此输入您的评价"
        show-word-limit
      />
      <button class="btn" @click="btn">提交评价</button>
    </div>
  </div>
</template>

<script>
import nav2 from '@/components/Nav2.vue'
import { complain_api, getComplaint_api } from '../http/api/service'
export default {
  name: 'TaxiH5OrderDetails',
  components: {
    nav2,
  },

  data() {
    return {
      message: '',
      value: 1,
      id:1,
      projectNameValue: '', //input框内显示的值
      projectListArr: [], //下拉的数据源，从接口中请求到数据，根据需要变成一维数组，只存name
       projectListArr2: [],
      showPicker: false, //弹出层显示隐藏
    }
  },

  mounted() {
    this.getComplaint()
  },

  methods: {
    changeConfirm(value, index) {
      console.log(value, index)
      this.projectNameValue=value
      this.showPicker=false
   this.id=index+1
    
     
    },
   
    //投诉原因
    async getComplaint() {
      let orderid = this.$route.query.orderid
      let carType = this.$route.query.carType
      let res = await getComplaint_api({
        iPathOrderId: orderid,
        carSource: carType,
      })
      console.log(res)
      if (res.code == 200) {
        res.data.Data.forEach((v) => {
          this.projectListArr.push(v.ComplaintValue)
        })
        this.projectListArr2 = res.data.Data
      }
    },
    //评价
    btn() {
      console.log(111)
      this.complain()
    },
    async complain() {
      let orderid = this.$route.query.orderid
      console.log(orderid)
      let carType = this.$route.query.carType
      let res = await complain_api({
        iPathOrderId: orderid,
        complaintReasonId: this.id,
        complaintReason: this.projectNameValue,
        complaintOtherDesc: this.message,
        carSource: carType,
      })
      console.log(res)
      if (res.code == 200) {
         window.history.go(-1)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  width: 100%;
  height: 100vh;
}
.btn {
  width: 90%;
  background: #eb4541;
  margin: 20px 5%;
  height: 50px;
  border: 0;
  border-radius: 15px;
  color: #fff;
  font-size: 14px;
}
</style>